<template>
  <div>
    <label> {{ label }}</label>
    <div class="textarea-box" :style="{height: Math.max(52, estimatedHeight+8) + 'px'}">
      <textarea rows="1" ref="textarea" :value="modelValue" @input="onUpdate" placeholder="..."></textarea>
    </div>
  </div>
</template>

<script>
export default {
  name : 'ProfileInput',
  props: ['label', 'modelValue'],
  emits: ['update:modelValue'],
  data() {
    return {
      estimatedHeight: 0
    }
  },
  mounted() {
    this.estimate(this.modelValue)
  },
  methods: {
    onUpdate($event) {
      this.$emit('update:modelValue', $event.target.value)
    },
    estimate(value) {
      const text           = this.$refs.textarea;
      text.value           = value
      text.style.height    = 'auto';
      text.style.height    = text.scrollHeight + 'px';
      this.estimatedHeight = text.scrollHeight;
    }
  },
  watch  : {
    modelValue(newVal) {
      this.estimate(newVal)
    }
  }
}
</script>

<style>
textarea {
  flex           : 1;
  border         : 0px solid #ddd;
  background     : transparent;
  color          : white;
  outline        : none;
  overflow-y     : hidden;
  resize         : none;
  font-size      : 1rem;
  line-height    : 1.5rem;
  padding     : .5rem .75rem;
  box-sizing: border-box;
  width          : 100%;
}

.textarea-box {
  position   : relative;
  width      : 100%;
  box-sizing : border-box;
  border-bottom: 1px solid #404040;
}

</style>