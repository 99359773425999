import { createStore } from 'vuex';

export default createStore(
  {
    state    : {
      account : undefined,
      total   : 0,
      favorite: 0
    },
    getters  : {
      getAccount : state => state.account,
      getTotal   : state => state.total,
      getFavorite: state => state.favorite
    },
    mutations: {
      setAccount : (state, payload) => state.account = payload,
      setTotal   : (state, payload) => state.total = payload,
      setFavorite: (state, payload) => state.favorite = payload
    }
  });
