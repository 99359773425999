<template>
  <div class="container profile-container" v-if="profile">
    <div class="chat-container" ref="scroll">
      <!-- Сообщения от пользователя -->
      <div v-for="(msg, index) in messages" class="message" :class="{'user': !msg.is_user, 'me': msg.is_user}" :key="index">
        <div class="message-content">{{ msg.text }}</div>
      </div>
    </div>
    <div class="message-container">
      <textarea class="message-textarea" v-model="sendMessage" placeholder="Сообщение" @keypress.enter.exact="send"></textarea>
      <button @click="send">send</button>
    </div>

    <div class="btn-group">
      <!--      <button class="btn btn-stop">СТОП</button>-->
      <!--      <button class="btn btn-write">Написать</button>-->
      <button class="btn btn-write" @click="favorite">Like</button>
      <button class="btn btn-write" @click="archive">Удалить</button>
      <button class="btn btn-photo" @click="open">Фотки</button>
    </div>

    <div class="photo-modal" v-if="isOpen">
      <div class="close" @click="close">
        <exit-icon></exit-icon>
      </div>
      <div v-if="description">
        description: {{ description }}
      </div>
      <Carousel :items-to-show="1">
        <Slide v-for="(photo, index) in profile.photos" :key="index">
          <img :src="photo">
        </Slide>
        <template #addons>
          <Navigation/>
          <Pagination/>
        </template>
      </Carousel>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import ExitIcon from '@/components/Icon/ExitIcon';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'
import moment from 'moment';

export default {
  name      : 'ProfileView',
  components: {
    ExitIcon,
    Carousel,
    Slide,
    Pagination,
    Navigation
  },
  props:['id'],
  data() {
    return {
      profile    : undefined,
      history    : [],
      messages   : [],
      sendMessage: '',
      isOpen     : false,
      short_info : {}
    }
  },
  mounted() {
    this.getProfile(this.id)
  },
  computed: {
    // id() {return this.$route.params.id},
    description() {return this.profile.info.length ? this.profile.info[0] : ''},
    account() {return this.$store.getters.getAccount}
  },
  methods : {
    async send() {
      console.log('send', this.sendMessage)
      let request = {
        chat   : this.profile.unique,
        chat_id: this.profile.id,
        text   : this.sendMessage,
        log    : {}
      };
      let response = await fetch('/api/chat/message', {
        method : 'POST',
        headers: {'token': this.account.token},
        body   : JSON.stringify(request)
      })
      console.log(await response.json())
      // await axios.post('/api/chat/message', {
      //   chat   : this.profile.unique,
      //   chat_id: this.profile.id,
      //   text   : this.sendMessage,
      //   log    : []
      // }, {headers: {'token': this.account.token}})
      this.sendMessage = ''
    },
    open() {
      this.isOpen = true
    },
    close() {
      this.isOpen = false
    },
    archive() {
      let status = window.confirm('Вы уверены?')
      if (status) {
        // console.log('remove', {user_id: this.profile.user_id, status: 'archive', stop: true})
        return fetch('/api/profile', {
          method : 'POST',
          headers: {'x-init': window.initData},
          body   : JSON.stringify({user_id: this.profile.user_id, status: 'ignore'})
        }).then(() => {
          this.$router.push({name: 'list', params: {'filter': 'finish'}})
        });
      }
    },
    favorite() {
      let status = window.confirm('Вы уверены?')
      if (status) {
        // console.log('remove', {user_id: this.profile.user_id, status: 'archive', stop: true})
        return fetch('/api/profile', {
          method : 'POST',
          headers: {'x-init': window.initData},
          body   : JSON.stringify({user_id: this.profile.user_id, status: 'favorite'})
        }).then(() => {
          this.$router.push({name: 'favorite-list'})
        });
      }
    },

    async scrollDown(e) {
      if (this.messages.length && this.messages[this.messages.length - 1].id !== this.lastMessageID) {
        this.lastMessageID = this.messages[this.messages.length - 1].id;
        await this.$forceUpdate();
        this.$refs.scroll.scrollTop = this.$refs.scroll.scrollHeight;
      }
      return e
    },
    getProfileInfo(is_user) {
      if (is_user) {
        return {
          firstname: 'Парень',
          photo    : '/images/avatar.png'
        }
      }
      // let result = this.userList.find(i => i.id === id);
      // console.log('result, id', result, id);
      return this.short_info
    },
    getDate(time) {
      return moment(time * 1000).format('DD/MM/YYYY')
    },
    ComputeHistory() {
      this.history = [];
      if (this.messages.length === 0) {
        return
      }
      this.date = this.getDate(this.messages[0].created_at);
      this.history.push({date: this.date, me: this.messages[0].me});
      this.idx = 0;
      this.history.push({me: this.messages[0].me, messages: [], profile: this.getProfileInfo(this.messages[0].is_user)});
      this.idx++;
      for (let i = 0; i < this.messages.length; i++) {
        this.nextMessage(this.messages[i])
      }
      this.scrollDown();
    },
    nextMessage(message) {
      let calc = this.getDate(message.created_at);
      if (calc !== this.date) {
        this.date = calc
        this.history.push({date: this.date, me: message.me});
        this.idx++;
        this.history.push({me: message.me, messages: [], profile: this.getProfileInfo(message.is_user)});
        this.idx++;
      }
      if (message.me !== this.history[this.idx].me) {
        this.history.push({me: message.me, messages: [], profile: this.getProfileInfo(message.is_user)});
        this.idx++
      }
      this.history[this.idx].messages.push(message)
    },
    getProfile(id) {
      return fetch('/api/chat/history', {
        method : 'POST',
        headers: {'x-init': window.initData},
        body   : JSON.stringify({id: id})
      }).then(data => data.json()).then(json => {
        this.profile  = json.info;
        let list = json.messages;
        if (json.pending.length){
          list.push(...json.pending)
        }
        this.messages = list.sort((a, b) => a.created_at - b.created_at).map(i => {
          i.me = i.is_user;
          return i;
        });

        this.ComputeHistory();
      });
    }
  },
  watch   : {
    id(newVal) {
      this.getProfile(newVal)
    }
  }
}
</script>

<style>
.profile-container{
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  background: #212121;
  box-sizing: content-box;
  z-index: 1;
}

.message-container {
  display        : flex;
  flex-direction : row;

}

.message-textarea {
  border-bottom : 1px solid #ddd;
  width         : 80%;
}

.message-container button {
  width      : 20%;
  background : none;
  color      : #ddd;
  border     : none;
}

.photo-modal {
  position        : absolute;
  z-index         : 1;
  top             : 0;
  left            : 0;
  width           : 100%;
  height          : 100vh;
  display         : flex;
  flex-direction  : column;
  justify-content : center;
  align-items     : center;
  background      : #212121;
}

.photo-modal .close {
  position      : absolute;
  top           : 10px;
  right         : 10px;
  background    : #2f2f2f;
  padding       : 10px;
  border-radius : 50px;
  z-index       : 111;
}

.carousel__prev, .carousel__next {
  height              : 100%;
  width               : 50%;
  justify-content     : left;
  -ms-touch-action    : manipulation;
  touch-action        : manipulation;
  cursor              : pointer;
  -webkit-user-select : none;
  -moz-user-select    : none;
  -ms-user-select     : none;
  user-select         : none;
}

.carousel__next {
  justify-content : right;
}

img {
  max-width : 100%;
}

.btn-group {
  display         : flex;
  justify-content : space-around;
  padding         : 10px 0;
}

.btn-group .btn {
  padding   : 5px 10px;
  border    : none;
  font-size : 18px;
}

.btn .btn-stop {

}

.btn .btn-write {}

.btn .btn-photo {}

.chat-container {
  max-width  : 800px;
  margin     : 0 auto;
  max-height : 70vh;
  overflow   : scroll;
}

.message {
  display       : flex;
  margin-bottom : 10px;
}

.message.user .message-content {
  background-color : #2d2d2d;
  align-self       : flex-start;
  margin-left      : 10px;
}

.message.user img {
  width         : 40px;
  height        : 40px;
  border-radius : 50%;
}

.message-content {
  padding       : 10px;
  border-radius : 10px;
  max-width     : 70%;
  line-height   : 1.4;
  word-wrap     : break-word;
}

.message.me {
  justify-content : flex-end;
  text-align      : right;
}

.message.me .message-content {
  background-color : #278bfa;
}

/* Скрываем аватар для последовательных сообщений */
.message.user.hide-avatar img {
  display : none;
}
</style>