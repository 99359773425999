<template>
  <profile-view v-if="id" :id="id"></profile-view>
  <div class="container">
    <Teleport to="#app">
      <div class="filter">
        <label class="search">
          <input type="text" :value="search" @input="debounceInput">
          <SearchIcon></SearchIcon>
        </label>
        <div class="status">
          <label for="location">
            {{ locationList[locationFilter] }}
          </label>
          <select id="location" v-model="locationFilter">
            <option v-for="(key,index) in Object.keys(locationList)" :key="index" :value="key">{{ locationList[key] }}</option>
          </select>
        </div>
        <div class="status">
          <label for="status">
            {{ statusList[status] }}
          </label>
          <select id="status" v-model="status">
            <option v-for="(key,index) in Object.keys(statusList)" :key="index" :value="key">{{ statusList[key] }}</option>
          </select>
        </div>
      </div>
    </Teleport>

    <div class="profile-list" @scroll="onScroll">
      <router-link class="profile" v-for="(item, index) in list" :key="index" :to="{name: 'list', params: {id: item.id}}">
        <img :src="$getPhoto(item.preview, 400, 600)">
        <div class="info">
          <span>{{ item.name }}</span>
        </div>
      </router-link>
      <div id="scroll-target"></div>
    </div>
  </div>
</template>

<script>
import SearchIcon from '@/components/Icon/SearchIcon';
import ProfileView from '@/views/ProfileView';
import debounce from 'debounce';

export default {
  name      : 'ProfilesView',
  props     : ['id'],
  components: {ProfileView, SearchIcon},
  data() {
    return {
      status        : 'finish',
      search        : '',
      locationFilter: 'all',
      locationList  : {
        'all'   : 'Все',
        'local' : 'Рядом',
        'remote': 'Удаленно'
      },
      statusList    : {
        'finish'    : 'Готовы',
        'processing': 'Общаемся',
        'stop'      : 'Остановлены',
        'ignore'    : 'Заблокированы',
        'all'       : 'Все'
      },
      list          : [],
      loading       : false,
      next          : true,
      page          : 1,
      perPage       : 24
    }
  },
  mounted() {
    this.getItems()
    this.list = []
  },
  computed: {},
  methods : {
    onScroll(event) {
      if (event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight < 100 && this.next) {
        this.getItems()
      }
    },
    async getItems() {
      if (this.loading) {
        return
      }
      this.loading = true;
      let body     = {status: this.status, mode: this.locationFilter, page: this.page, perPage: this.perPage};
      if (this.search) {
        body.search = this.search;
      }
      if (body.status === 'all') {
        delete body.status;
      }
      if (body.mode === 'all') {
        delete body.mode;
      }
      return fetch('/api/chat/filter', {
        method : 'POST',
        headers: {'x-init': window.initData},
        body   : JSON.stringify(body)
      }).then(data => data.json()).then(json => {
        this.list.push(...json.result);
        this.page    = json.page + 1;
        this.perPage = json.perPage;
        this.next    = json.result.length === json.perPage;
        this.loading = false;
      }).finally(() => {
        this.loading = false;
      })
    },
    debounceInput: debounce(function (e) {
      this.search = e.target.value;
    }, 500)
  },
  watch   : {
    search() {
      this.list = []
      this.page = 1
      this.getItems()
    },
    status() {
      this.list = []
      this.page = 1
      this.getItems()
    },
    locationFilter() {
      this.list = []
      this.page = 1
      this.getItems()
    }
  }
}
</script>

<style>
.filter {
  display         : flex;
  justify-content : space-between;
  gap             : 10px;
  position        : fixed;
  top             : 0;
  left            : 0;
  width           : 100%;
  padding         : 5px 10px;
  min-height      : 40px;
  background      : #212121;
  box-shadow      : 0 0 5px #212121;
}

.search {
  width    : 70%;
  display  : block;
  position : relative;
}

.search svg {
  position  : absolute;
  left      : 0;
  top       : 50%;
  transform : translate(10px, -50%);
  fill      : white;
}

.search input {
  width         : 100%;
  font-size     : 20px;
  color         : white;
  padding       : 10px 15px 10px 40px;
  border-radius : 40px;
  border        : 1px solid #2f2f2f;
  background    : transparent;
}

.status {
  width           : 30%;
  position        : relative;
  display         : flex;
  justify-content : center;
  align-items     : center;

  background      : linear-gradient(to top right, #fd267a, #ff6036);
  border-radius   : 50px;
}

.status label {
  color          : white;
  font-weight    : bold;
  text-transform : capitalize;
}

.status select {
  font-size : 20px;
  width     : 100%;
  height    : 100%;
  opacity   : 0;
  position  : absolute;
  border    : none;
  left      : 0;
  top       : 0;
}


.profile-list {
  display               : grid;
  grid-template-columns : repeat(2, 1fr);
  gap                   : 10px;
  margin-top            : 45px;
  max-height            : calc(100vh - 116px);
  overflow-y            : scroll;
  overflow-x            : hidden;
}

.profile {
  position      : relative;
  border-radius : 10px;
  overflow      : hidden;
}

.profile img {
  width      : 100%;
  height     : 100%;
  object-fit : cover;
}

.profile .info {
  position       : absolute;
  bottom         : 0;
  left           : 0;
  padding-bottom : 15px;
  padding-left   : 15px;
  font-size      : 18px;
  font-weight    : bold;
  width          : 100%;
  text-shadow    : 2px 2px 4px rgba(0, 0, 0, 0.5);
  background     : linear-gradient(to top, #212121, #ffffff00);
}

</style>