<template>
    <div class="preloader">
      <div class="spinner"></div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'PreloaderComponent'
  }
  </script>
  
  <style scoped>
  .preloader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #212121;
  }
  
  .spinner {
    border: 16px solid #f3f3f3;
    border-top: 16px solid #3498db;
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  </style>